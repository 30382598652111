import React, { Component } from "react";

import Header from "./Header";
import Footer from "./Footer";

// router import
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
// transition
import { CSSTransition, TransitionGroup } from "react-transition-group";

// import playload from API
import fetchURLs from "../components/services/wpClient";

import Page from "../pages/Page";
import Home from "../pages/Home";
import Bio from "../pages/Bio";
import Contact from "../pages/Contact";
import Dates from "../pages/Dates";
import SinglePost from "../pages/SinglePost";

// Does the user's browser support the HTML5 history API?
// If the user's browser doesn't support the HTML5 history API then we
// will force full page refreshes on each page change.
const supportsHistory = "pushState" in window.history;
class App extends Component {
  constructor() {
    super();
    this.state = {
      playload: { loaded: false }
    };
  }

  getData() {
    let playloadD = fetchURLs();
    let checkLoaded = setInterval(() => {
      this.setState({ playload: playloadD });
      if (this.state.playload.loaded) {
        clearInterval(checkLoaded);

        setTimeout(() => {
          document.body.classList.remove("loading");
        }, 500);
      }
    }, 5000);
  }
  componentDidMount() {
    this.getData();
  }

  renderNotLoaded() {
    // console.log("renderNotLoaded");
    return null;
  }
  renderLoaded() {
    // console.log("renderLoaded");
    return (
      <Router forceRefresh={!supportsHistory}>
        {/*meta data header*/}
        <Header
          mainMenu={this.state.playload.WPAPI.mainMenu}
          acf={this.state.playload.WPAPI.acf}
        />
        <main className="App">
          <Route
            render={({ location }) => {
              const { pathname } = location;
              return (
                <TransitionGroup>
                  <CSSTransition
                    key={pathname}
                    classNames="page"
                    timeout={{
                      enter: 1000,
                      exit: 2000
                    }}
                  >
                    <Route
                      location={location}
                      render={() => (
                        <Switch>
                          <Route
                            path="/"
                            exact
                            render={props => (
                              <Home
                                {...props}
                                allPosts={this.state.playload.WPAPI.allPosts}
                                acf={this.state.playload.WPAPI.acf}
                              />
                            )}
                          />

                          <Route
                            path="/bio/"
                            render={props => (
                              <Bio
                                {...props}
                                page={this.state.playload.WPAPI.page}
                              />
                            )}
                          />
                          <Route
                            path="/contact/"
                            render={props => (
                              <Contact
                                {...props}
                                page={this.state.playload.WPAPI.page}
                              />
                            )}
                          />
                          <Route
                            path="/dates/"
                            render={props => (
                              <Dates
                                {...props}
                                page={this.state.playload.WPAPI.page}
                              />
                            )}
                          />
                          {/* generic page */}
                          <Route
                            path="/:page"
                            render={props => (
                              <Page
                                {...props}
                                page={this.state.playload.WPAPI.page}
                              />
                            )}
                          />
                          <Route path="/post/:post" component={SinglePost} />
                        </Switch>
                      )}
                    />
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />
        </main>
        <Footer acf={this.state.playload.WPAPI.acf} />
      </Router>
    );
  }
  render() {
    // console.log("render");
    return (
      <React.Fragment>
        {this.state.playload.loaded
          ? this.renderLoaded()
          : this.renderNotLoaded()}
      </React.Fragment>
    );
  }
}

export default App;
