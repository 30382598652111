import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { acf: this.props.acf };
  }

  render() {
    let logoUrl = this.state.acf;

    return (
      <div id="logo">
        <Link className="logoLink" to="/">
          homepage
          <img src={logoUrl.acf.logo.url} alt={logoUrl.acf.logo.alt}/>
        </Link>
      </div>
    );
  }
}

export default Logo;
