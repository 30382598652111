import React, { Component } from "react";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      currentPage: this.getCurrentPage()
    };
  }

  getCurrentPage() {
    const currentPath = window.location.pathname
      .replace("/", "")
      .replace("/", "");
    for (var i = 0; i < this.props.page.length; i++) {
      if (this.props.page[i].slug === currentPath) {
        return this.props.page[i];
      }
    }
  }

  render() {
    const page = this.state.currentPage;
    return (
      <div id={page ? page.slug : "404" } className="container page">
        <div className="content">
          <div className="titleContainer">
            <div className="title">
              <h1 id="pageTitle">
              {page ? page.title.rendered : "404" } {page ? page.title.rendered : "404" } {page ? page.title.rendered : "404" }
              </h1>
              <h1 id="pageTitle">
              {page ? page.title.rendered : "404" } {page ? page.title.rendered : "404" } {page ? page.title.rendered : "404" }
              </h1>
            </div>
          </div>
          {page ? <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} /> : <div dangerouslySetInnerHTML={{ __html: "<h2>Erreur 404</h2><p>Vous vous êtes perdu, cette page n'existe pas.</p>" }} /> }
          
        </div>
      </div>
    );
  }
}

export default Page;
