import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Accordion from "./Accordion";
import PolylineLink from "./PolylineLink";
import {Osc} from "./Osc";
// transition
import { CSSTransition, TransitionGroup } from "react-transition-group";

class PostsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 0,
      allPosts: this.props.allPosts,
      currentPost: this.props.allPosts[0],
      acf: this.props.acf
    };
  }

  componentDidUpdate() {
    Accordion();
  }
  componentDidMount(){
    this.selectPost('', 0)
  }
  /**
   * display post
   */

  selectPost = (e, index) => {
    this.setState({ currentPost: this.state.allPosts[index] });
    this.setState({
      activeItem: index
    });
  };
  showPost = post => {
    function dontShowIfEmpty(string) {
      if (post.content.rendered !== "") {
        return string;
      }
    }
    return (
      <article className="postItem">
        <div className="postLink title">
          {/* <Link
          className="postLink title"
          to={{
            pathname: `post/${post.slug}`,
            state: { from: this.state.allPosts.location }
          }}
        > */}
          <h1>{post.title.rendered}</h1>
          <h1>{post.title.rendered}</h1>
          {/* </Link> */}
        </div>
        <div className="row">
          <div className="postLink thumbnail">
            {/* <Link
            className="postLink thumbnail"
            to={{
              pathname: `post/${post.slug}`,
              state: { from: this.state.allPosts.location }
            }}
          > */}
            <img src={post.featured_image_url} alt="" />
          </div>
          {/* </Link> */}
          <div className="what accordion">
            <h2>projet</h2>
            <div className="content">
              <h3>{dontShowIfEmpty("what")}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: post.content.rendered }}
              />
              <h3>tracks</h3>
              <ul className="tracks">
                {post.acf.tracks.map(function(item, i) {
                  return (
                    <li key={i}>
                      {item.duration}
                      <span>{item.trackname}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* <div className="tracks accordion">
            <h2>écoute</h2>
            <ul className="content">
              {post.acf.ecoute.map(function(item, i) {
                return (
                  <li key={i}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.platform}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div> */}
          <div id="listenButtonContainer">
              {post.acf.ecoute.map(function(item, i) {
                return (
                  <div className="listenButton" key={i}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.platform}
                    </a>
                  </div>
                );
              })}
            </div>
        </div>
      </article>
    );
  };
  render() {

    let postLoopSelector = this.state.allPosts.map((post, index) => {
      return (
        <li
          className={
            "select " + this.state.activeItem === index ? "active" : ""
          }
          key={index}
        >
          <button className="osc" onClick={e => this.selectPost(e, index)}>
            ep {index}
            <Osc index={index} />
          </button>
        </li>
      );
    });
    let postLoopAnchor = this.state.allPosts.map((post, index) => {
      return <li className="anchor" key={index} />;
    });
    let postLoopPolyline = this.state.allPosts.map((post, index) => {
      return (
        <polyline
          className={this.state.activeItem === index ? "active" : ""}
          key={index}
        />
      );
    });

    return (
      <div id="postContainer">
        <TransitionGroup>
          <CSSTransition
            key={this.state.currentPost.id}
            classNames="post"
            timeout={{
              enter: 1000,
              exit: 2000
            }}
          >
            <div className="post">{this.showPost(this.state.currentPost)}</div>
          </CSSTransition>
        </TransitionGroup>
        <div id="linksContainer">
          <ul className="anchorContainer">{postLoopAnchor}</ul>
          <div className="polylineContainer">
            <svg id="svg">{postLoopPolyline}</svg>
          </div>
          <ul className="selectContainer">{postLoopSelector}</ul>
        </div>
        {PolylineLink()}
      </div>
    );
  }
}

export default PostsList;
