import React, { Component } from "react";

import PostsList from "../components/PostsList";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allPosts: this.props.allPosts,
      acf: this.props.acf
    };
  }

  render() {
    return (
      <div id="home" className="container page">
        <div className="content">
          <PostsList allPosts={this.state.allPosts} acf={this.state.acf} />
        </div>
      </div>
    );
  }
}

export default Home;
