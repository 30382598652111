import React from "react";

class CanvasComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.updateCanvas(this.props.index);
  }
  updateCanvas(index) {
    const canvas = this.refs.canvas;
    const ctx = this.refs.canvas.getContext("2d");
    // ctx.fillStyle = "green";
    // ctx.fillRect(0,0, 100, 100);

    ((ctx, w, h, index) => {
      const maxValue = h - 5;
      const graphWidth = w;
      const graphHeight = maxValue;
      let curY = h - graphHeight / 2;
      let curX = 50;
      let waves = [];
      let tick = 0;
      let waveFormIndex = index;
      function Main() {
        canvas.width = w;
        canvas.height = h;
        this.init();
      }

      Main.prototype = {
        init: function() {
          /**
           * Initialize the waveforms and push them into 'waves'.
           * The first argument is the display name,
           * the second the function that takes 1 argument (i)
           * which is the value to evaluate the function to.
           */
          waves.push(new WaveForm("1", i => i % maxValue));

          waves.push(
            new WaveForm("2", i =>
              (i % maxValue) * 2 < maxValue ? maxValue : 0
            )
          );

          waves.push(
            new WaveForm("3", i =>
              Math.abs((i % (maxValue * 2)) - maxValue)
            )
          );

          waves.push(
            new WaveForm(
              "4",
              i => (maxValue / 2) * Math.sin(i / 10) + maxValue / 2
              // the divider ( / 24.5) is only added to spread the graph, you can leave it out or change the value to whatever you like
            )
          );
          waves.push(
            new WaveForm(
              "5",
              i => Math.tan(i / 20) % maxValue
              // the divider ( / 24.5) is only added to spread the graph, you can leave it out or change the value to whatever you like
            )
          );
          waves.push(
            new WaveForm(
              "6",
              i => Math.pow((i % maxValue) / (maxValue / 6), 2) % maxValue
              // the divider ( / 24.5) is only added to spread the graph, you can leave it out or change the value to whatever you like
            )
          );
          waves.push(new WaveForm("7", i => i % maxValue));
          waves.push(
            new WaveForm("8", i =>
              (i % maxValue) * 2 < maxValue ? maxValue : 0
            )
          );

          waves.push(
            new WaveForm("9", i =>
              Math.abs((i % (maxValue * 2)) - maxValue)
            )
          );

          waves.push(
            new WaveForm(
              "10",
              i => (maxValue / 2) * Math.sin(i / 10) + maxValue / 2
              // the divider ( / 24.5) is only added to spread the graph, you can leave it out or change the value to whatever you like
            )
          );
          waves.push(
            new WaveForm(
              "11",
              i => Math.tan(i / 20) % maxValue
              // the divider ( / 24.5) is only added to spread the graph, you can leave it out or change the value to whatever you like
            )
          );
          waves.push(
            new WaveForm(
              "12",
              i => Math.pow((i % maxValue) / (maxValue / 6), 2) % maxValue
              // the divider ( / 24.5) is only added to spread the graph, you can leave it out or change the value to whatever you like
            )
          );

          this.request = requestAnimationFrame(() => this.draw());
        },
        draw: function() {
          this.request = requestAnimationFrame(() => this.draw());

          ctx.clearRect(0, 0, w, h);
          tick++;

          ctx.strokeStyle = "white";
          ctx.lineWidth = 2;
          ctx.lineCap = "round";

          if (waves[waveFormIndex]) {
            waves[waveFormIndex].draw(tick);
          }
        }
      };

      function WaveForm(name, fn) {
        // this.name = name;
        this.fn = fn;
        this.x = 0;
        this.y = 1 + waves.length;
      }

      WaveForm.prototype = {
        draw: function(currentTick) {
          // plot the entire graph first
          this.plotGraph();
        },
        plotGraph: function() {
          ctx.beginPath();
          ctx.moveTo(this.x, this.y + this.fn(tick));
          for (var i = 1; i < graphWidth; i++)
            ctx.lineTo(this.x + i, this.y + this.fn(i + tick));
          ctx.stroke();
          ctx.closePath();
        }
      };

      const main = new Main();
    })(ctx, this.props.width, this.props.height, index);
  }
  render() {
    return <canvas ref="canvas" />;
  }
}

class Osc extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  render() {
    return (
      <div>
        <CanvasComponent width={70} height={30} index={this.props.index} />
      </div>
    );
  }
}

export {Osc, CanvasComponent};

