import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { acf: this.props.acf };
  }

  render() {
    let rs = this.state.acf.acf.rs;
    return (
      <footer id="footer" className="row fadeIn">
        <ul className="footerLink">
          {rs.map(function(item, i) {
            return (
              <li key={i}>
{/* style={{backgroundColor: item.couleur}} */}
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.platform}
                </a>
              </li>
            );
          })}
        </ul>
      </footer>
    );
  }
}

export default Footer;
