/**
 * polyline link
 */
const PolylineLink = () => {
  // console.log('polyline test');
  //constructor
  var firstLi = document.querySelectorAll("ul.anchorContainer li.anchor");
  var anchorContainer = document.querySelector("ul.anchorContainer");
  var selectContainer = document.querySelector("ul.selectContainer");
  // console.log(firstLi);
  var liLength = firstLi.length;
  var lastLi = document.querySelectorAll("ul.selectContainer li");
  var polylines = document.querySelectorAll("polyline");
  //draw line
  var linksContainer = document.getElementById("linksContainer");

  let drawLines = () => {
    //   console.log('drawline test');
    let drawLinesDesktop = () => {
      for (var i = 0; i < liLength; i++) {
        // console.log('drawline in loop test');
        // var firstLiPos = firstLi[i].getBoundingClientRect();
        // var lastLiPos = lastLi[i].getBoundingClientRect();
        // console.log(firstLiPos.right, firstLiPos.left);
        var firstLiPostop = 0;
        var lastLiPostop = 300;
        var firstLiPosY =
          firstLi[i].offsetTop -
          anchorContainer.offsetTop +
          firstLi[i].offsetHeight / 2;
        var lastLiPosY = lastLi[i].offsetTop + lastLi[i].offsetHeight / 2;
        if (i <= liLength / 2) {
          polylines[i].setAttribute(
            "points",
            "" +
              0 +
              "," +
              lastLiPosY +
              "," +
              (liLength / 2 - i) * 15 +
              "," +
              lastLiPosY +
              "," +
              (liLength / 2 - i) * 15 +
              "," +
              firstLiPosY +
              "," +
              firstLi[i].offsetLeft +
              "," +
              firstLiPosY +
              ""
          );
        } else {
          polylines[i].setAttribute(
            "points",
            "" +
              0 +
              "," +
              lastLiPosY +
              "," +
              (i - liLength / 2 + 1) * 15 +
              "," +
              lastLiPosY +
              "," +
              (i - liLength / 2 + 1) * 15 +
              "," +
              firstLiPosY +
              "," +
              firstLi[i].offsetLeft +
              "," +
              firstLiPosY +
              ""
          );
        }
      }
    };
    let drawLinesMobile = () => {
      for (var i = 0; i < liLength; i++) {
        // console.log('drawline in loop test');
        // var firstLiPos = firstLi[i].getBoundingClientRect();
        // var lastLiPos = lastLi[i].getBoundingClientRect();
        // console.log(firstLiPos.right, firstLiPos.left);
        var firstLiPostop = 0;
        var lastLiPostop = 300;
        var firstLiPosX =
          firstLi[i].offsetLeft -
          anchorContainer.offsetLeft +
          firstLi[i].offsetWidth / 2;
        var lastLiPosX =
          lastLi[i].offsetLeft -
          selectContainer.offsetLeft +
          lastLi[i].offsetWidth / 2;
        // console.log(firstLiPosX);
        if (i <= liLength / 2) {
          polylines[i].setAttribute(
            "points",
            "" +
              firstLiPosX +
              "," +
              firstLiPostop +
              "," +
              firstLiPosX +
              "," +
              ((i + 1) * firstLi[i].offsetWidth + 30) +
              "," +
              lastLiPosX +
              "," +
              ((i + 1) * firstLi[i].offsetWidth + 30) +
              "," +
              lastLiPosX +
              "," +
              lastLiPostop +
              ""
          );
        } else {
          polylines[i].setAttribute(
            "points",
            "" +
              firstLiPosX +
              "," +
              firstLiPostop +
              "," +
              firstLiPosX +
              "," +
              ((liLength - i) * firstLi[i].offsetWidth + 30) +
              "," +
              lastLiPosX +
              "," +
              ((liLength - i) * firstLi[i].offsetWidth + 30) +
              "," +
              lastLiPosX +
              "," +
              lastLiPostop +
              ""
          );
        }
      }
    };

    var linksContainer = document.querySelector("#linksContainer");
    if (linksContainer) {
      var linksContainerFD = getComputedStyle(linksContainer).getPropertyValue(
        "flex-direction"
      );
      if (linksContainerFD === "row-reverse") {
        drawLinesDesktop();
      } else {
        drawLinesMobile();
      }
    }
  };
  // init
  drawLines();
  // on resize
  window.addEventListener("resize", function(event) {
    drawLines();
  });
};

export default PolylineLink;
