import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { siteUrl } from "../components/services/wpClient";
import { toggleMenu } from "./Header";
import helmet_polyline_black from "../assets/img/helmet_polyline.gif"

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: -1,
      mainMenu: this.props.mainMenu,
      acf: this.props.acf
    };
  }
  componentDidMount() {
    this.setState({
      activeItem: 0
    });
  }
  setActiveItem = (e, index) => {
    this.setState({
      activeItem: index
    });
  };
  render() {
    // console.log(.mainMenu);
    // console.log(this.state.mainMenu);
    let menuItems = this.state.mainMenu;
    let activeItem = this.state.activeItem;
    const addActive = index => {
      if (activeItem === index) {
        return "active";
      } else {
      }
    };

    let menuLoop = menuItems.map((item, index) => {
      let menuLinkSan = () => {
        return item.url.replace(siteUrl, "");
      };
      return (
        <li
          key={index}
          className={"menuItem " + addActive(index)}
          // className={" " + activeItem === index ? "active" : ""}
          onClick={e => {
            toggleMenu(e);
            this.setActiveItem(e, index);
          }}
        >
          <Link className="menuLink " to={menuLinkSan()}>
            {item.title}
          </Link>
        </li>
      );
    });
    let popupActive = this.state.acf.acf.active_le_popup;
    let popupTitre = this.state.acf.acf.titre_popup;
    let popupLink = this.state.acf.acf.lien_popup;


    return (
      <div id="menu">
        <nav>
          <ul>{menuLoop}</ul>
        </nav>
        {popupActive ? <div id="popupInMenu" className="popup"><a href={popupLink} target="_blank" rel="noopener noreferrer"><img src={helmet_polyline_black} alt="icone lien vers le filtre instagram" /><p>{popupTitre}</p></a></div> : null}
        <h1>SOPHONIC</h1>
      </div>
    );

    // return (
    //   <Router>
    //     <div>
    //       <nav>
    //         <ul>
    //           <li>
    //             <Link to="/">Home</Link>
    //           </li>
    //           <li>
    //             <Link to="/about/">About</Link>
    //           </li>
    //           <li>
    //             <Link to="/contact/">Contact</Link>
    //           </li>
    //         </ul>
    //       </nav>

    //       <Route path="/" exact component={Home} />
    //       <Route path="/about/" component={About} />
    //       <Route path="/contact/" component={Contact} />
    //       <Route path="/post/:post" component={SinglePost} />
    //     </div>
    //   </Router>
    // );
  }
}

export default Menu;
