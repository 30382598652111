const Accordion = e => {
  const accordions = document.querySelectorAll(".accordion h2");
  for (var item of accordions) {
    let parent = item.parentNode;
    let classToggle = () => {
      parent.classList.toggle("open");
    };
    item.onclick = e => {
      classToggle();
    };
  }
};

export default Accordion;
