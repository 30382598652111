const siteUrl = "https://admin.sophonic.fr";

// MEILLEUR FETCHHH à la mano en loop

let fetchURLs = () => {
  const urls = [
      siteUrl + "/wp-json/wp/v2/pages",
      siteUrl + "/wp-json/wp/v2/posts",
      // siteUrl + "/wp-json/wp/v2/posts?categories=2",
      siteUrl + "/wp-json/menus/v2/header",
      siteUrl + "/wp-json/acf/v2/options"
    ],
    keyName = [
      "page",
      "allPosts",
      // "portfolios",
      "mainMenu",
      "acf"
    ];
  //declare loaded false & WPAPI as an object
  let playload = { loaded: false, WPAPI: {} };
  // init async pour resoudre promesse et return value
  let init = () => {
    // fetching one and add to obj WPAPI
    const fetchURL = (urlApi, index) => {
      fetch(urlApi)
        .then(response => response.json())
        .then(data => {
          let currentKeyName = keyName[index];
          playload.WPAPI[currentKeyName] = data;

          // playload.loaded = true;

          return playload;
        })
        .catch(error => {
          // console.log(error.message);
          playload.loaded = false;
        });
      // console.log("fetching.....");

      return playload;
    };

    // fetching loop
    Promise.all(urls.map((url, index) => fetchURL(url, index))).then(() => {
      playload.loaded = true;

      return playload;
    });

    return playload;
  };
  init();

  return playload;
};

export default fetchURLs;
export { siteUrl };
