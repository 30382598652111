import React, { Component } from "react";
import axios from "axios";
import { siteUrl } from "./services/wpClient";

const API_PATH = "http://sophonic.fr/api/contact/index.php";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      email: "",
      message: "",
      mailSent: false,
      error: null
    };
  }

  handleFormSubmit = e => {
    e.preventDefault();
    let fname = this.state.fname;
    let email = this.state.email;
    let message = this.state.message;
    if (fname == "" || email == "" || message == "") {
      this.setState({ error: "Merci de renseigner tous les champs" });
    } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email) == false) {
      this.setState({ error: "Merci de renseigner un email correct" });
    } else {
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: this.state
      })
        .then(result => {
          this.setState({
            mailSent: result.data.sent
          });
          // console.log(this.state);
        })
        .catch(error => this.setState({ error: "Erreur de connexion, réessayez" }));
    }
  };

  messageExist = e => {
    if (this.state.mailSent || this.state.error) {
      document.getElementById("messageInfo").classList.add("message");
      // console.log(this.state.error);
    }
    if (this.state.mailSent) {
      document.querySelector("div.submit").classList.add("sent");
    }
  };

  render() {
    return (
      <div className="contactForm">
        <h2>Contact</h2>
        <div className="formContainer">
          <form action="#">
            <label>Nom</label>
            <input
              required
              type="text"
              id="fname"
              name="firstname"
              value={this.state.fname}
              onChange={e => this.setState({ fname: e.target.value })}
            />

            <label>Email</label>
            <input
              required
              type="email"
              id="email"
              name="email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />

            <label>Message</label>
            <textarea
              required
              id="message"
              name="message"
              onChange={e => this.setState({ message: e.target.value })}
              value={this.state.message}
            />

            <div className="submit">
              <input
                type="submit"
                onClick={e => this.handleFormSubmit(e)}
                value="envoyer le message"
              />
            </div>
            <div id="messageInfo">
              {this.messageExist()}
              {this.state.mailSent && (
                <div className="success">Votre message a été envoyé</div>
              )}
              {this.state.error && (
                <div className="error">
                  <p>{this.state.error}</p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
