import React, { Component } from "react";
import BackButton from "../components/BackButton";

class SinglePost extends Component {
  render() {
    return (
      <div className="container">
        <div className="content">
          <h2>Single POST</h2>
          <p>Je suis un single post</p>
          <BackButton />
        </div>
      </div>
    );
  }
}

export default SinglePost;
