import React, { Component } from "react";
import ContactForm from "../components/ContactForm";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      currentPage: this.getCurrentPage()
    };
  }

  getCurrentPage() {
    const currentPath = window.location.pathname
      .replace("/", "")
      .replace("/", "");
    for (var i = 0; i < this.props.page.length; i++) {
      if (this.props.page[i].slug === currentPath) {
        return this.props.page[i];
      }
    }
  }

  render() {
    const page = this.state.currentPage;
    return (
      <div id={page.slug} className="container page">
        <div className="content">
          <div className="titleContainer">
            <div className="title">
              <h1 id="pageTitle">
                {page.title.rendered} {page.title.rendered}{" "}
                {page.title.rendered}
              </h1>
              <h1 id="pageTitle">
                {page.title.rendered} {page.title.rendered}{" "}
                {page.title.rendered}
              </h1>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
          <ContactForm />
        </div>
      </div>
    );
  }
}

export default Contact;
