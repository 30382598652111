import React, { Component } from "react";

class Dates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      currentPage: this.getCurrentPage()
    };
  }

  getCurrentPage() {
    const currentPath = window.location.pathname
      .replace("/", "")
      .replace("/", "");
    for (var i = 0; i < this.props.page.length; i++) {
      if (this.props.page[i].slug === currentPath) {
        return this.props.page[i];
      }
    }
  }

  componentWillMount() {
    const script = document.createElement("script");

    script.src = "https://widget.bandsintown.com/main.min.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    const page = this.state.currentPage;
    return (
      <div id={page.slug} className="container page">
        <div className="content">
          <div className="titleContainer">
            <div className="title">
              <h1 id="pageTitle">
                {page.title.rendered} {page.title.rendered}{" "}
                {page.title.rendered}
              </h1>
              <h1 id="pageTitle">
                {page.title.rendered} {page.title.rendered}{" "}
                {page.title.rendered}
              </h1>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />

          <a
            className="bit-widget-initializer"
            data-artist-name="sophonic"
            data-display-local-dates="false"
            data-display-past-dates="false"
            data-auto-style="false"
            data-text-color="#FFFFFF"
            data-link-color="#DFDED7"
            data-background-color="rgba(0,0,0,0)"
            data-display-limit=""
            data-link-text-color="#363636"
            data-display-lineup="false"
            data-display-play-my-city="false"
            data-separator-color="rgba(124,124,124,0.25)"
            data-language="fr"
          />
        </div>
      </div>
    );
  }
}

export default Dates;
