import React from "react";
import Menu from "./Menu";
import Logo from "./Logo";
import helmet_polyline_black from "../assets/img/helmet_polyline.gif"

const toggleMenu = (e, data) => {
  const elements = document.querySelectorAll("#header,#burgerMenu");
  for (var item of elements) {
    item.classList.toggle("open");
  }
};
// This syntax ensures `this` is bound within handleClick.
// Warning: this is *experimental* syntax.

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acf: this.props.acf
    };
  }

  render() {
    let popupActive = this.state.acf.acf.active_le_popup;
    let popupTitre = this.state.acf.acf.titre_popup;
    let popupLink = this.state.acf.acf.lien_popup;
    return (
      <header id="header" className="row">
        <Menu mainMenu={this.props.mainMenu} acf={this.props.acf}/>
        <Logo acf={this.props.acf} />
        <div id="burgerMenu" onClick={(e, data) => toggleMenu(e, data)}>
          <span className="burgerStroke" />
          <span className="burgerStroke" />
          <span className="burgerStroke" />
        </div>
        {popupActive ? <div id="popupDesktop" className="popup"><a href={popupLink} target="_blank" rel="noopener noreferrer"><img src={helmet_polyline_black} alt="icone lien vers le filtre instagram" /><p>{popupTitre}</p></a></div> : null}
        <p className="credit">made with ♥ by<br></br><a href="https://wasabi-artwork.com" target="_blank" rel="noopener noreferrer">Wasabi Artwork</a><br></br>All Rights Reserved</p>
      </header>
    );
  }
}

export default Header;
export { toggleMenu };
